import React from 'react'

import AuthProvider from 'contexts/AuthProvider'
import UserProvider from 'contexts/UserProvider'
import Layout from 'components/Layout'
import NewsFeed from 'components/NewsFeed'

export default function NewsPage() {
  return (
    <AuthProvider>
      <UserProvider>
        <Layout className="news-page">
          <NewsFeed />
        </Layout>
      </UserProvider>
    </AuthProvider>
  )
}
